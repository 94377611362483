//
// user.scss
// Use this to write your custom SCSS
//

// overrides
.navbar {
    padding: 0.25rem 0;
}
.navbar-brand-img {
    max-height: 5.4rem;
}